import { Controller } from '@hotwired/stimulus';

import config from '../config';

export default class extends Controller {
  static values = { target: String };

  open(event) {
    const customEvent = new CustomEvent(config.events.modal.open, {
      detail: {
        params: event.params,
        target: this.target,
      },
    });

    window.dispatchEvent(customEvent);
  }

  get target() {
    return document.querySelector(this.targetValue);
  }
}
