import { useThrottle } from 'stimulus-use';
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static throttles = ['submit'];

  connect() {
    useThrottle(this, { wait: 100 });
  }

  submit() {
    const { adapter } = Turbo.session;
    adapter.progressBar.setValue(0);
    adapter.showProgressBar();

    requestAnimationFrame((() => this.element.requestSubmit()));
  }
}
