import { Controller } from '@hotwired/stimulus';
import config from '../config';

export default class extends Controller {
  static classes = ['open'];

  connect() {
    window.addEventListener(config.events.modal.open, this.showOverlay.bind(this));
    window.addEventListener(config.events.modal.close, this.hideOverlay.bind(this));
  }

  open() {
    window.dispatchEvent(new Event(config.events.modal.open));
  }

  close() {
    window.dispatchEvent(new Event(config.events.modal.close));
  }

  showOverlay() {
    document.body.classList.add(this.openClass);
  }

  hideOverlay() {
    document.body.classList.remove(this.openClass);
  }
}
