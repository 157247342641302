import ScrollTo from 'stimulus-scroll-to';

export default class extends ScrollTo {
  scroll(event) {
    event.preventDefault();

    const id = this.element.hash.replace(/^#/, '');
    const target = document.getElementById(id);

    if (!target) return;

    target.scrollIntoView(true);
  }
}
