import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'multiselect',
    'select',
    'radioButton',
    'input',
    'target',
    'checkbox',
  ];

  static values = {
    hide: Boolean,
    hideClass: String,
    revealOn: String,
    invertSignal: Boolean,
  };

  connect() {
    if (this.targetTargets.length > 1) {
      this.revealTargets = this.targetTargets;
      this.multipleRevealTargets = true;
    } else {
      this.revealTarget = this.targetTarget;
      this.multipleRevealTargets = false;
    }
    this.shouldInvertSignal = this.hasInvertSignalValue ? this.invertSignalValue : false;
    this.shouldHide = this.hasHideValue ? this.hideValue : true;
    this.revealOnValues = this.revealOnValue.split(',');
    this.hideClasses = this.hasHideClassValue ? this.hideClassValue.split(' ') : ['hide'];
    if (this.hasSelectTarget) {
      this.type = 'select';
      this.select = this.selectTarget;
      this.select.addEventListener('change', this.toggle.bind(this));
    } else if (this.hasMultiselectTarget) {
      this.type = 'multiselect';
      this.multiselect = this.multiselectTarget;
      this.multiselect.addEventListener('change', this.toggle.bind(this));
    } else if (this.hasRadioButtonTarget) {
      this.type = 'radio';
      this.radioButtons = this.radioButtonTargets;
      this.radioButtons.forEach((radioButton) => {
        radioButton.addEventListener('change', this.toggle.bind(this));
      });
    } else if (this.hasInputTarget) {
      this.type = 'input';
      this.inputTarget.addEventListener('input', this.toggle.bind(this));
    } else if (this.hasCheckboxTarget) {
      this.type = 'checkbox';
      this.checkbox = this.checkboxTarget;
      this.checkbox.addEventListener('change', this.toggle.bind(this));
    }
  }

  toggle() {
    const value = this.currentValue();
    // We add hide first to trigger the animation and then add hidden to fix
    // the tabindexes
    if (this.multipleRevealTargets) {
      this.toggleTargets(value);
    } else if (this.shouldReveal(value)) {
      this.reveal(this.revealTarget);
    } else {
      this.hide(this.revealTarget);
    }
  }

  toggleTargets(value) {
    this.revealTargets.forEach((element) => {
      if (element.dataset.revealOn.split(',').includes(value)) {
        this.reveal(element);
      } else {
        this.hide(element);
      }
    });
  }

  reveal(element) {
    if (this.shouldHide) element.classList.remove('hidden');
    setTimeout(() => {
      this.hideClasses.forEach((clas) => element.classList.remove(clas));
    }, 100);
  }

  hide(element) {
    this.hideClasses.forEach((clas) => element.classList.add(clas));
    if (this.shouldHide) element.classList.add('hidden');
  }

  currentValue() {
    switch (this.type) {
      case 'multiselect':
        // use apply to cast the HTMLOptionsCollection to an array
        return [...this.multiselect.options]
          .filter((option) => option.selected)
          .map((option) => option.value);
      case 'select':
        return this.select.value;
      case 'radio':
        return this.radioButtons.find((radioButton) => radioButton.checked)?.value;
      case 'input':
        return this.inputTarget.value;
      case 'checkbox':
        return this.checkbox.checked;
      default:
        throw new Error(`Unknown type for currentValue: ${this.type}`);
    }
  }

  shouldReveal(value) {
    switch (this.type) {
      case 'multiselect':
        // If any revealOnValues are currently selected we shouldReveal
        return (this.revealOnValues.filter(
          (revealOnValue) => value.includes(revealOnValue),
        ) > 0) === !this.shouldInvertSignal;
      case 'select':
        return this.revealOnValues.includes(value) === !this.shouldInvertSignal;
      case 'radio':
        return this.revealOnValues.includes(value) === !this.shouldInvertSignal;
      case 'input':
        return Boolean(value) === !this.shouldInvertSignal;
      case 'checkbox':
        return value === !this.shouldInvertSignal;
      default:
        throw new Error(`Unknown type for shouldReveal: ${this.type}`);
    }
  }
}
