import { Controller } from '@hotwired/stimulus';
import amplitude from 'amplitude-js';

export default class extends Controller {
  static values = {
    apiKey: String,
    environment: String,
    instance: String,
    userId: String,
    userName: String,
  };

  connect() {
    if (!this.isApply) { return; }

    this.initializeInstance();

    if (this.userNameValue) {
      const userProperties = { Name: this.userNameValue, ...this.utmParams };
      this.instance.setUserProperties(userProperties);
    }

    this.instance.logEvent('Page Load', this.pageLoadAttributes);
  }

  initializeInstance() {
    if (this.userIdValue) {
      this.instance.init(this.apiKeyValue, this.userIdValue);
      return;
    }

    this.instance.init(this.apiKeyValue);
    this.instance.setUserId(null);
    this.instance.regenerateDeviceId();
  }

  get isApply() {
    return this.subdomain === 'apply';
  }

  get instance() {
    return amplitude.getInstance(this.instanceValue);
  }

  get pageLoadAttributes() {
    const { pathname: path, href: url, search } = window.location;
    const { referrer, title } = document;

    return {
      subdomain: this.subdomain,
      path,
      url,
      search,
      referrer,
      title,
    };
  }

  get utmParams() {
    const re = /\butm_([^=]+)=([^&]+)/g;
    const qs = window.location.search.split('+').join(' ');

    const params = {};
    let tokens;

    while ((tokens = re.exec(qs)) !== null) { // eslint-disable-line no-cond-assign
      params[`utm_${decodeURIComponent(tokens[1])}`] = decodeURIComponent(tokens[2]);
    }

    return params;
  }

  get subdomain() {
    return window.location.host.split('.')[0];
  }
}
