import { Controller } from '@hotwired/stimulus';
import { useDispatch } from 'stimulus-use';

export default class extends Controller {
  connect() {
    useDispatch(this);

    if (!this.element.classList.contains('toast--active')) return;

    this.dispatch('begin');
    this.element.addEventListener('animationend', () => this.dispatch('end'));
    this.element.addEventListener('click', this.dismiss);
  }

  flash(event) {
    const newToast = this.element.cloneNode(true);

    newToast.classList.add('toast--active');
    const icon = newToast.querySelector('i');
    if (event.detail.type === 'success') {
      icon.className = 'icon icon-check';
    } else {
      // Hide the icon
      icon.className = 'icon';
      newToast.classList.add('toast--alert');
    }

    newToast.querySelector('span').innerText = event.detail.message;

    this.element.replaceWith(newToast);
  }

  dismiss = () => {
    this.element.getAnimations().forEach((animation) => animation.finish());
  };
}
