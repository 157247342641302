import TextareaAutogrow from 'stimulus-textarea-autogrow';

export default class extends TextareaAutogrow {
  static values = {
    focusRows: Number,
    blurRows: Number,
    maxHeight: Number,
  };

  connect() {
    super.connect();

    if (this.element.scrollHeight === 0) { // element is initially hidden
      this.element.style.height = `${this.elementHeight + 20}px`;
    }
  }

  autogrow() {
    if (this.element.value) {
      // Retain original behaviour when textarea has contents
      super.autogrow();

      if (!this.hasMaxHeightValue) { return; }

      if (this.element.offsetHeight > this.maxHeightValue) {
        this.element.style.overflow = 'hidden scroll';
        this.element.style.height = `${this.maxHeightValue}px`;
        return;
      }

      this.element.style.overflow = 'hidden';
      return;
    }

    // Change focus & blur heights only when `row` attribute values are set
    if (this.hasFocusRowsValue && this.hasBlurRowsValue) {
      const rows = (this.element === document.activeElement)
        ? this.focusRowsValue : this.blurRowsValue;

      // clear the height (if any) set by `autogrow`
      this.element.style.height = 'initial';
      this.element.setAttribute('rows', rows);
    }

    const applyTextArea = document.getElementsByClassName('text_with_counter');
    if (applyTextArea.length > 0 && this.linesCount === 1) {
      this.element.style.height = `${this.elementHeight}px`;
    }
  }

  getStyle(el, styleProp) {
    let y = null;
    if (el.currentStyle) {
      y = el.currentStyle[styleProp];
    } else if (window.getComputedStyle) {
      y = document.defaultView.getComputedStyle(el, null).getPropertyValue(styleProp);
    }
    return y;
  }

  get elementHeight() {
    return (this.linesCount * this.lineHeight) + this.yPaddingHeight;
  }

  parseInt(value) {
    const parsed = parseInt(value, 10);

    return Number.isNaN(parsed) ? 0 : parsed;
  }

  get linesCount() {
    return this.element.value.split('\n').length;
  }

  get lineHeight() {
    const heightPx = this.getStyle(this.element, 'line-height');

    return this.parseInt(heightPx);
  }

  get yPaddingHeight() {
    let sum = 0;
    const paddingTopPx = this.getStyle(this.element, 'padding-top');
    const padingBottomPx = this.getStyle(this.element, 'padding-bottom');

    if (paddingTopPx) sum += this.parseInt(paddingTopPx);
    if (padingBottomPx) sum += this.parseInt(padingBottomPx);

    return sum;
  }
}
