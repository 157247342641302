import { Controller } from '@hotwired/stimulus';

// data-alert-animation-class defines the list of classes that will be added to
// animate the alert, like fade out or slide out. defaults to .hide
//
// data-alert-auto-close="5000" can be used to set the duration (microseconds)
// to auto close.
export default class extends Controller {
  connect() {
    this.animateClasses = (this.element.dataset.animationClass || 'hide').split(' ');

    if (this.element.dataset.autoClose !== undefined) {
      if (this.element.dataset.autoClose === 'true') {
        setTimeout(() => this.close(), 500);
      } else {
        setTimeout(() => this.close(), this.element.dataset.autoClose);
      }
    }
  }

  close() {
    this.element.classList.add(...this.animateClasses);
    if (this.element.dataset.removeAnimationClassAfter !== undefined) {
      setTimeout(
        () => this.element.classList.remove(...this.animateClasses),
        parseInt(this.element.dataset.removeAnimationClassAfter, 10),
      );
    }
    let timeout = parseInt(this.element.dataset.removeAfter, 10);
    if (timeout === undefined) { timeout = 0.5 * 1000; }
    setTimeout(() => this.element.remove(), timeout);
  }
}
