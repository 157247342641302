import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['form', 'button', 'errors', 'scrim'];

  open() {
    this.formTarget.classList.remove('h-0');
    this.formTarget.classList.remove('opacity-0');
    this.formTarget.classList.remove('-top-[1000px]');
    this.formTarget.classList.add('h-screen');
    this.formTarget.classList.add('md:h-full');
    this.buttonTarget.classList.add('hidden');
    this.scrimTarget.classList.add('hidden');
  }

  back() {
    this.formTarget.classList.add('h-0');
    this.formTarget.classList.add('opacity-0');
    this.formTarget.classList.add('-top-[1000px]');
    this.formTarget.classList.remove('h-screen');
    this.formTarget.classList.remove('md:h-full');
    this.buttonTarget.classList.remove('hidden');
    this.scrimTarget.classList.remove('hidden');

    if (this.hasErrorsTarget) this.errorsTarget.classList.add('hidden');
  }
}
