import { Controller } from '@hotwired/stimulus';
import validate from 'validate.js';

const PRESENCE_RULE = {
  allowEmpty: false,
  message(_value, attribute, _validatorOptions, _attributes, _globalOptions) { // eslint-disable-line no-unused-vars
    return validate.format('^%{attribute} is required', {
      attribute: validate.capitalize(validate.prettify(attribute)),
    });
  },
};

const BASE_RULES = {
  required: { presence: PRESENCE_RULE },
  email: { presence: PRESENCE_RULE, email: true },
  password: {
    length: {
      minimum: 6,
      maximum: 128,
    },
  },
};

export default class extends Controller {
  static classes = ['wrapper', 'error', 'errorField'];

  connect() {
    this.errors = new Map();
    this.rules = new Map();

    this.validationElements.forEach((element) => this.setupValidation(element));
  }

  setupValidation(element) {
    const {
      validationAttribute,
      validationRules,
      validationEquality,
    } = element.dataset;

    const rules = validationRules.split(',');

    rules.forEach((rule) => {
      let constraints = {};

      switch (rule) {
        case 'equality': {
          // Prepare comparator function for equality comparisons
          const target = this.element.querySelector(validationEquality);
          const targetName = target.dataset.validationAttribute;

          constraints.equality = {
            attribute: targetName,
            comparator: ((value) => value === target.value),
          };
          break;
        }

        default:
          constraints = BASE_RULES[rule];
          break;
      }

      this.rules.set(validationAttribute, constraints);
    });
  }

  validate(event) {
    this.validateElement(event.target);
  }

  validateElement(elem) {
    const { validationAttribute } = elem.dataset;
    const constraints = this.rules.get(validationAttribute);
    const result = validate(
      { [validationAttribute]: elem.value },
      { [validationAttribute]: constraints },
    );

    if (result) {
      this.errors.set(validationAttribute, result[validationAttribute]);
    } else {
      this.errors.delete(validationAttribute);
    }

    this.afterValidate({ elem, attribute: validationAttribute });
  }

  validateAll(event) {
    this.validationElements.forEach((elem) => this.validateElement(elem));

    if (this.errors.size) {
      // Block form submission
      event.preventDefault();
    }
  }

  afterValidate({ elem, attribute }) {
    if (this.hasError(attribute)) {
      this.displayErrorMessage({ elem, attribute });
      return;
    }

    this.clearErrorMessage({ elem, attribute });
  }

  displayErrorMessage({ elem, attribute }) {
    const wrapper = this.elementWrapper(elem);
    const errorField = this.getOrCreateErrorField(elem);
    const [errorMessage] = this.errors.get(attribute);

    elem.classList.add(this.errorClass);
    wrapper.classList.add(this.errorClass);
    errorField.innerText = errorMessage;
  }

  clearErrorMessage({ elem }) {
    const wrapper = this.elementWrapper(elem);
    const errorField = this.elementErrorField(wrapper);

    elem.classList.remove(this.errorClass);
    wrapper.classList.remove(this.errorClass);

    if (errorField) {
      errorField.remove();
    }
  }

  elementWrapper(elem) {
    return elem.closest(`.${this.wrapperClass}`);
  }

  elementErrorField(wrapper) {
    return wrapper.querySelector(`.${this.errorFieldClass}`);
  }

  hasError(attribute) {
    return Boolean(this.errors.get(attribute));
  }

  getOrCreateErrorField(elem) {
    const wrapper = this.elementWrapper(elem);
    let errorField = this.elementErrorField(wrapper);

    if (!errorField) {
      errorField = document.createElement('p');
      errorField.className = this.errorFieldClass;
      wrapper.appendChild(errorField);
    }

    return errorField;
  }

  get validationElements() {
    return this.element.querySelectorAll('[data-validation-rules]');
  }
}
