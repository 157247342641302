import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.element.addEventListener('change', this.toggle.bind(this));
  }

  disconnect() {
    this.element.removeEventListener('change', this.toggle.bind(this));
  }

  toggle() {
    const currentValue = this.element.value;
    if (currentValue === '') {
      this.element.classList.remove('selected');
    } else {
      this.element.classList.add('selected');
    }
  }
}
