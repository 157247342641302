import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    threshold: { type: Number, default: 10 },
  };

  static classes = ['scroll'];

  renderShadow({ target }) {
    if (target.scrollTop > this.thresholdValue) {
      this.element.classList.add(this.scrollClass);
      return;
    }

    this.element.classList.remove(this.scrollClass);
  }
}
