import { Controller } from '@hotwired/stimulus';
import { useDispatch } from 'stimulus-use';

export default class extends Controller {
  static values = {
    source: String,
    sourceType: String,
    message: { type: String, default: 'Link copied' },
  };

  connect() {
    useDispatch(this);
  }

  copy() {
    navigator.clipboard.writeText(this.sourceValue);
    this.dispatch('copy', {
      sourceType: this.sourceTypeValue,
      type: 'success',
      message: this.messageValue,
    });
  }
}
