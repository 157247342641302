import application from 'controllers/application';
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';
import Flatpickr from 'stimulus-flatpickr';
import { Dropdown } from 'tailwindcss-stimulus-components';

application.register('flatpickr', Flatpickr);
application.register('dropdown', Dropdown);

const context = require.context('controllers/shared', true, /_controller\.js$/);
application.load(definitionsFromContext(context));
