import { Controller } from '@hotwired/stimulus';
import { useDispatch } from 'stimulus-use';

export default class extends Controller {
  static targets = ['icon', 'detail'];

  static classes = ['open'];

  connect() {
    this.isOpen = this.element.classList.contains(this.openClass);
    this.render();
    useDispatch(this);
  }

  toggle() {
    this.isOpen = !this.isOpen;
    this.render();

    this.dispatch('toggle', { open: this.isOpen });
  }

  render() {
    if (this.isOpen) {
      this.open();
      return;
    }

    this.close();
  }

  open() {
    this.element.classList.add(this.openClass);
  }

  close() {
    this.element.classList.remove(this.openClass);
  }
}
