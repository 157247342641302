import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  initialize() {
    this.historyAdapter = Turbo.session.history;
    this.pushStateAdapter = window.history.pushState;
  }

  update(event) {
    this
      .historyAdapter
      .update(
        this.pushStateAdapter,
        this.formLocationFromTurboEvent(event),
      );

    this.hideProgressBar();
  }

  formLocationFromTurboEvent(event) {
    return event.detail.formSubmission.fetchRequest.location;
  }

  hideProgressBar() {
    const { adapter } = Turbo.session;
    const { progressBar } = adapter;

    if (!progressBar.visible) { return; }

    progressBar.setValue(1);
    adapter.hideVisitProgressBar();
  }
}
