import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['preview', 'blankPreview'];

  static classes = ['image'];

  preview(event) {
    const file = event.target.files[0];

    if (this.hasPreviewTarget) {
      this.previewTarget.src = URL.createObjectURL(file);
    } else if (this.hasBlankPreviewTarget) {
      const image = document.createElement('img');
      image.className = this.imageClass;
      image.src = URL.createObjectURL(file);
      image.dataset.filePreviewTarget = 'preview';

      this.blankPreviewTarget.parentElement.prepend(image);
      this.blankPreviewTarget.remove();
    }
  }
}
