import { Controller } from '@hotwired/stimulus';
import * as Choices from 'choices.js';

export default class extends Controller {
  static values = {
    maxItemCount: Number,
    maxItemText: String,
    placeholder: String,
  };

  initialize() {
    this.maxItemCount = this.maxItemCountValue || -1;
    this.maxItemText = this.maxItemTextValue || 'Only 3 values can be added';
    this.placeholder = this.hasPlaceholderValue;
  }

  connect() {
    if (this.choices !== undefined) return;

    this.choices = new Choices(
      this.element,
      {
        maxItemCount: this.maxItemCount,
        maxItemText: this.maxItemText,
        placeholder: this.placeholder,
        placeholderValue: this.placeholderValue,
        shouldSort: false,
        classNames: {
          containerOuter: 'choices w-full',
          containerInner: 'choices__inner form-input',
        },
      },
    );
  }

  handleChange(event) {
    const input = event.target;
    const inputWrapper = input.closest('.input-wrapper');
    const errorWrapper = input.closest('.input-wrapper').querySelector('p.form-error');
    const promptText = document.querySelector('.choices__input--cloned');
    if (inputWrapper !== null) { inputWrapper.classList.remove('has-error'); }
    if (errorWrapper !== null) { errorWrapper.classList.add('hide'); }
    if (input.options.length) {
      promptText.placeholder = '';
    } else {
      promptText.placeholder = 'Select';
      promptText.style.cssText = 'width: auto;';
    }
  }
}
