import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input'];

  static values = {
    wrapperSelector: String,
    errorWrapperSelector: String,
    inputErrorClass: String,
    hideClass: String,
    inputSiblingSelector: String,
    altInputSelector: String,
  };

  initialize() {
    this.wrapperSelector = this.wrapperSelectorValue || '.input-wrapper';
    this.errorWrapperSelector = this.errorWrapperSelectorValue || 'p.form-error';
    this.inputErrorClass = this.inputErrorClassValue || 'has-error';
    this.hideClass = this.hideClassValue || 'hide';
    this.inputSiblingSelector = '.form-input-group-prepend, .form-input-group-append';
    this.altInputSelector = '.flatpickr-alt-input';
    this.radioOrCheckbox = (input) => (input.type === 'radio' || input.type === 'checkbox');
  }

  connect() {
    this.inputTargets.forEach((input) => {
      if (!input.classList.contains(this.inputErrorClass)) return;

      input.setAttribute('data-error-value', input.value);
      if (this.radioOrCheckbox(input)) {
        input.addEventListener('change', this.toggleErrorVisibility.bind(this));
      } else {
        input.addEventListener('input', this.toggleErrorVisibility.bind(this));
      }
    });
  }

  toggleErrorVisibility(e) {
    const input = e.currentTarget;
    const inputWrapper = input
      .closest(this.wrapperSelector);
    const errorWrapper = input
      .closest(this.wrapperSelector)
      .querySelector(this.errorWrapperSelector);
    const inputSibling = input
      .closest(this.wrapperSelector)
      .querySelector(this.inputSiblingSelector);
    const altInput = input
      .closest(this.wrapperSelector)
      .querySelector(this.altInputSelector);

    // Fairly safe to assume all radios and checkboxes are valid options and
    // errors on occur if no option is checked so we use null
    const errorValue = this.radioOrCheckbox(e.currentTarget) ? null : input.dataset.errorValue;

    if (this.currentValue(e) === errorValue) {
      inputWrapper.classList.remove(this.inputErrorClass);
      errorWrapper.classList.remove(this.hideClass);
      input.classList.add(this.inputErrorClass);
      if (inputSibling !== null) { inputSibling.classList.add(this.inputErrorClass); }
      if (altInput !== null) { altInput.classList.add(this.inputErrorClass); }
    } else {
      inputWrapper.classList.remove(this.inputErrorClass);
      errorWrapper?.classList.add(this.hideClass);
      input.classList.remove(this.inputErrorClass);
      if (inputSibling !== null) { inputSibling.classList.remove(this.inputErrorClass); }
      if (altInput !== null) { altInput.classList.remove(this.inputErrorClass); }
    }
  }

  currentValue(e) {
    const input = e.currentTarget;

    if (this.radioOrCheckbox(input)) {
      const siblings = input
        .closest('.input-group')
        .querySelectorAll('input[type=checkbox], input[type=radio]');

      return [...siblings].find((option) => option.checked).value;
    }
    return input.value;
  }

  handleChange(event) {
    if (event.target.classList.contains('has-error')) {
      event.target.classList.remove('has-error');
    }
  }
}
