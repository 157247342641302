import { Controller } from '@hotwired/stimulus';
import config from '../config';

export default class extends Controller {
  static classes = ['show'];

  connect() {
    window.addEventListener(config.events.modal.open, this.showModal.bind(this));
    window.addEventListener(config.events.modal.close, this.hideModal.bind(this));
  }

  close() {
    window.dispatchEvent(new Event(config.events.modal.close));
  }

  showModal(event) {
    if (!this.isCurrentElement(event.detail.target)) { return; }

    this.element.classList.add(this.showClass);
  }

  hideModal() {
    this.element.classList.remove(this.showClass);
  }

  isCurrentElement(target) {
    return target === this.element;
  }
}
