import { Controller } from '@hotwired/stimulus';
import { useDispatch } from 'stimulus-use';

import hotkeys from 'hotkeys-js';
import config from '../config';

const OPEN_SCOPE = 'open';
const CLOSE_SCOPE = 'close';

export default class extends Controller {
  static targets = ['dropdownLink', 'autoFocussable', 'disclosure', 'sublist'];

  static values = {
    autoPositionClearance: { type: Number, default: 16 },
  };

  static classes = ['invisible'];

  connect() {
    useDispatch(this);
    window.addEventListener(config.events.modal.open, this.selfClose.bind(this));

    if (this.hasDropdownLinkTarget) {
      hotkeys('up,down', { scope: OPEN_SCOPE }, this.navigate);
    }
  }

  disconnect() {
    hotkeys.deleteScope(OPEN_SCOPE);
  }

  open = () => {
    this.dispatch('close');
    this.element.open = true;
  };

  close = (event) => {
    const isOpen = Boolean(this.element.open);
    if (!isOpen) { return; }

    let shouldClose = false;

    switch (event.type) {
      case 'click':
        shouldClose = !this.element.contains(event.target);
        break;
      case 'keydown':
        shouldClose = true;
        break;
      default:
        shouldClose = false;
    }

    if (shouldClose) {
      this.selfClose();
    }
  };

  selfClose = () => {
    this.element.removeAttribute('open');

    if (this.hasDisclosureTarget) {
      this.disclosureTarget.classList.add(this.invisibleClass);
    }

    if (this.hasSublistTarget) {
      this.sublistTarget.classList.add(this.invisibleClass);
    }
  };

  isOpen = () => this.element.open;

  navigate = (event) => {
    let direction = 0;
    let focusIndex = this.dropdownLinkTargets
      .findIndex((elem) => elem === document.activeElement);

    switch (event.key) {
      case 'ArrowDown':
        direction = 1; // forward
        break;
      case 'ArrowUp':
        direction = -1; // backward
        break;
      default:
        direction = 0;
    }

    focusIndex = Math.min(
      Math.max(focusIndex + direction, 0),
      this.dropdownLinkTargets.length - 1,
    );

    this.dropdownLinkTargets[focusIndex].focus();
  };

  setScope() {
    let scope = CLOSE_SCOPE;

    if (this.element.open) {
      scope = OPEN_SCOPE;

      if (this.hasAutoFocussableTarget) {
        this.autoFocussableTarget.focus();
      }
    }

    hotkeys.setScope(scope);
  }

  autoPosition() {
    if (!this.hasDisclosureTarget) { return; }

    const {
      height: disclosureHeight,
      width: disclosureWidth,
    } = this.disclosureTarget.getBoundingClientRect();
    const {
      height: detailHeight,
      top: detailTop,
      left: detailLeft,
      right: detailRight,
    } = this.element.getBoundingClientRect();

    const exceedsBottomViewport = (
      detailTop + detailHeight + this.autoPositionClearanceValue + disclosureHeight
    ) >= window.innerHeight;
    const exceedsTopViewport = (detailTop - this.autoPositionClearanceValue - disclosureHeight) < 0;
    const exceedsRightViewport = (detailLeft + disclosureWidth) >= window.innerWidth;
    const position = {};

    if (exceedsBottomViewport && !exceedsTopViewport) {
      position.bottom = window.innerHeight - detailTop + this.autoPositionClearanceValue;
    } else {
      position.top = detailTop + detailHeight + this.autoPositionClearanceValue;
    }

    if (exceedsRightViewport) {
      position.right = window.innerWidth - detailRight;
    } else {
      position.left = detailLeft;
    }

    this.disclosurePosition = position;
    this.disclosureTarget.classList.remove(this.invisibleClass);
  }

  repositionSublist({ target }) {
    const {
      top: parentTop,
      right: parentRight,
      bottom: parentBottom,
      left: parentLeft,
    } = target.getBoundingClientRect();
    const { height, width } = this.sublistTarget.getBoundingClientRect();

    const position = {
      top: parentTop,
      bottom: 'initial',
      left: `${parentRight}px`,
    };

    const exceedsBottomViewport = (parentTop + height) > window.innerHeight;
    const exceedsRightViewport = (parentRight + width) >= window.innerWidth;

    if (exceedsBottomViewport) {
      position.top = 'initial';
      position.bottom = window.innerHeight - parentBottom;
    }

    if (exceedsRightViewport) {
      position.left = parentLeft - width;
    }

    this.sublistPosition = position;
    this.sublistTarget.classList.remove(this.invisibleClass);
  }

  assignPosition(element, position) {
    ['top', 'right', 'bottom', 'left'].forEach((dimension) => {
      let value = position[dimension] || 'initial';

      if (typeof value === 'number') { value = `${value}px`; }

      // eslint-disable-next-line no-param-reassign
      element.style[dimension] = value;
    });
  }

  get mainContentWidth() {
    return document.querySelector('#main').getBoundingClientRect().width;
  }

  set disclosurePosition(position) {
    this.assignPosition(this.disclosureTarget, position);
  }

  set sublistPosition(position) {
    this.assignPosition(this.sublistTarget, position);
  }
}
