import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['section', 'link'];

  static values = {
    offset: Number,
    scrollContainer: String,
    targetSelectorAttr: String,
  };

  static classes = ['active'];

  initialize() {
    this.recheckActiveSection = this.recheckActiveSection.bind(this);
  }

  connect() {
    if (this.scrollContainer === undefined) return;

    this.scrollContainer.addEventListener('scroll', this.recheckActiveSection);
    window.addEventListener('resize', this.recheckActiveSection);
  }

  disconnect() {
    this.scrollContainer.removeEventListener('scroll', this.recheckActiveSection);
    window.removeEventListener('resize', this.recheckActiveSection);
  }

  recheckActiveSection() {
    const section = this.getSectionInView();
    const activeLinks = this.getLinksBySectionId(section);

    if (activeLinks === undefined || activeLinks.length === 0) return;
    if (this.allCurrentlyActive(activeLinks)) return;

    this.removeCurrentActive(activeLinks);
    this.setActive(activeLinks);
  }

  allCurrentlyActive(links) {
    return links.filter(this.isActiveLink.bind(this)).length === links.length;
  }

  isActiveLink(link) {
    const appliedClasses = this.activeClasses.filter((c) => link.classList.contains(c));

    return appliedClasses.length === this.activeClasses.length;
  }

  getSectionInView() { // eslint-disable-line consistent-return
    for (let i = 0; i < this.sectionTargets.length; i += 1) {
      const rect = this.sectionTargets[i].getBoundingClientRect();

      const isInView = rect.top - this.offset <= 0 && rect.bottom > this.offset;

      if (isInView) return this.sectionTargets[i];
    }
  }

  scrollContainerScrollTop() {
    if (this.scrollContainer.pageYOffset !== undefined) {
      return this.scrollContainer.pageYOffset;
    }
    return this.scrollContainer.scrollTop;
  }

  findLinkTargets(selector) {
    return this.linkTargets.filter((linkTarget) => {
      const elements = linkTarget.parentNode.querySelectorAll(selector);

      return Array.prototype.indexOf.call(elements, linkTarget) !== -1;
    });
  }

  filterLinkTargets(selector) {
    return this.linkTargets.filter((linkTarget) => {
      const elements = linkTarget.parentNode.querySelectorAll(selector);

      return Array.prototype.indexOf.call(elements, linkTarget) !== -1;
    });
  }

  getLinksBySectionId(section) {
    if (!section) return;

    return this.findLinkTargets(`[${this.targetSelectorAttr}="#${section.getAttribute('id')}"]`); // eslint-disable-line consistent-return
  }

  setActive(activeLinks) {
    this.activeClasses.forEach((className) => {
      activeLinks.forEach((link) => {
        if (!link.classList.contains(className)) {
          link.classList.add(className);
        }
      });
    });
  }

  removeCurrentActive(activeLinks) {
    const activeClassSelector = this.activeClasses.join('.');
    const attr = this.targetSelectorAttr;
    const notList = activeLinks.map((link) => `:not([${attr}="${link.getAttribute(attr)}"])`);
    const otherActiveLinksSelector = `.${activeClassSelector}${notList.join('')}`;
    const linkTargets = this.filterLinkTargets(otherActiveLinksSelector);

    linkTargets.forEach((linkTarget) => {
      this.activeClasses.forEach((className) => {
        if (linkTarget.classList.contains(className)) {
          linkTarget.classList.remove(className);
        }
      });
    });
  }

  // Offset from viewport before the activeClasses is applied
  get offset() {
    return this.offsetValue || 0;
  }

  // Normally document but can be overriden to scrollable element
  get scrollContainer() {
    if (!this.hasScrollContainerValue) return window;
    if (this.scrollContainerValue instanceof HTMLElement) {
      return this.scrollContainerValue;
    }
    return document.querySelector(this.scrollContainerValue);
  }

  // where the sectionId will be found. Typically href e.g.
  //   <a href='#mySection'>.
  // Can be overriden to
  //   <a href='mysite.com/page#mySection' data-target='#mySection'>
  // by specifying
  //   data-scrollspy-target-selector-attr-value='data-target'
  get targetSelectorAttr() {
    return this.targetSelectorAttrValue || 'href';
  }

  get activeClasses() {
    if (this.hasActiveClass) return super.activeClasses;

    return ['active'];
  }
}
