import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['menu', 'button'];

  static values = { open: Boolean };

  connect() {
    this.activeClass = 'active';
    if (this.hasButtonTarget) {
      this.buttonTarget.addEventListener('keydown', this.onMenuButtonKeydown.bind(this));
    }

    this.element.setAttribute('aria-haspopup', 'true');
  }

  disconnect() {
    if (this.hasButtonTarget) {
      this.buttonTarget.removeEventListener('keydown', this.onMenuButtonKeydown.bind(this));
    }
  }

  toggle() {
    this.openValue = !this.openValue;
  }

  openValueChanged() {
    if (this.openValue) {
      this.showMenu();
    } else {
      this.hideMenu();
    }
  }

  showMenu() {
    document.body.classList.add('overflow-hidden');
    this.addSwipeListener();
    this.element.setAttribute('aria-expanded', 'true');
    this.activeClassList[0].forEach((klass) => {
      this.element.classList.add(klass);
    });
  }

  hideMenu() {
    document.body.classList.remove('overflow-hidden');
    this.removeSwipeListener();
    this.element.setAttribute('aria-expanded', 'false');
    this.activeClassList[0].forEach((klass) => this.element.classList.remove(klass));
  }

  onMenuButtonKeydown(event) {
    const key = event.key || event.keyCode || event.which;
    const enterPressed = key === 'Enter' || key === 13;
    const spacePressed = key === ' ' || key === 32;

    if (enterPressed || spacePressed) {
      event.preventDefault();
      this.toggle();
    }
  }

  show() {
    this.openValue = true;
  }

  hide(event) {
    if (this.element.contains(event.target) === false && this.openValue) {
      this.openValue = false;
    }
  }

  get activeClassList() {
    return !this.activeClass
      ? [[], []]
      : this.activeClass.split(',').map((classList) => classList.split(' '));
  }

  addSwipeListener() {
    // Only handle swipes on mobile screens
    if (window.screen.width > 650) return;

    this.touchstartX = null;
    this.touchstartY = null;
    this.touchendX = null;
    this.touchendY = null;
    this.element.addEventListener('touchstart', this.swipeStart.bind(this));
    this.element.addEventListener('touchend', this.swipeEnd.bind(this));
  }

  removeSwipeListener() {
    this.element.removeEventListener('touchstart', this.swipeStart.bind(this));
    this.element.removeEventListener('touchend', this.swipeEnd.bind(this));
  }

  swipeStart(event) {
    this.touchstartX = event.changedTouches[0].screenX;
    this.touchstartY = event.changedTouches[0].screenY;
  }

  swipeEnd(event) {
    this.touchendX = event.changedTouches[0].screenX;
    this.touchendY = event.changedTouches[0].screenY;
    this.handleGesture();
  }

  handleGesture() {
    const delx = this.touchendX - this.touchstartX;
    const dely = this.touchendY - this.touchstartY;
    if (Math.abs(delx) > Math.abs(dely)) {
      // swiped right
      if (delx > 0) this.openValue = false;
    }
  }
}
