import { Controller } from '@hotwired/stimulus';
import { useHotkeys } from 'stimulus-use';

export default class extends Controller {
  static values = {
    keys: Array,
  };

  connect() {
    const config = {};
    const handler = (key) => (event) => this.emitEvent(key, event);

    this.keysValue.forEach((key) => { config[key] = [handler(key)]; });
    useHotkeys(this, config);

    hotkeys.filter = ({
      code, key, target, metaKey, shiftKey, altKey, ctrlKey,
    }) => {
      if (!/^(INPUT|TEXTAREA|SELECT)$/.test(document.activeElement.tagName)) {
        return true;
      }

      let actionName;

      if (metaKey) {
        actionName = `hotkey:command+${key.toLowerCase()}@window`;
      } else if (shiftKey) {
        actionName = `hotkey:shift+${key.toLowerCase()}@window`;
      } else if (altKey) {
        actionName = `hotkey:alt+${key.toLowerCase()}@window`;
      } else if (ctrlKey) {
        actionName = `hotkey:ctrl+${key.toLowerCase()}@window`;
      }

      return (
        (target.dataset.action && target.dataset.action.includes(actionName)) ||
          (
            code === 'Escape' &&
            (target.closest('.modal') || target.closest('details'))
          )
      );
    };
  }

  emitEvent(key, event) {
    const hotkeyEvent = new CustomEvent(`hotkey:${key}`, { detail: event });

    window.dispatchEvent(hotkeyEvent);
  }
}
