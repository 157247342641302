import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['ignore'];

  static values = { to: String };

  connect() {
    this.scrollArea = this.hasToValue
      ? document.querySelector(this.toValue)
      : window;
    this.element.addEventListener('mousewheel', this.scrollPassthru.bind(this));
  }

  ignoreTargetConnected(element) {
    element.addEventListener('mousewheel', this.ignore.bind(this));
  }

  ignoreTargetDisonnected(element) {
    element.removeEventListener('mousewheel', this.ignore.bind(this));
  }

  scrollPassthru(event) {
    event.preventDefault();

    this.scrollArea.scrollTop += event.deltaY;
  }

  ignore(event) {
    event.stopPropagation();
  }
}
