import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    focusPlaceholder: String,
    blurPlaceholder: String,
    classNames: { type: Array, default: ['absolute', 'inset-x-0', 'z-10'] },
  };

  expand() {
    this.element.classList.add(...this.classNamesValue);
    this.element.placeholder = this.focusPlaceholderValue;
  }

  contract() {
    this.element.classList.remove(...this.classNamesValue);
    this.element.placeholder = this.blurPlaceholderValue;
  }
}
