const config = {
  events: {
    application: {
      assign: 'assign',
    },
    modal: {
      open: 'openModal',
      close: 'closeModal',
    },
    hotkeys: 'hotkeyPressed',
  },
};

export default config;
